#capacity {
  width: 60px;
  height: 60px;
  background-color: #000;
  border: 1px solid gray;
  position: fixed;
  bottom: 0;
  left: 420px;
}

.progressBar {
  z-index: -1;
  height: 100%;
  background-color: #d2684880;
  position: absolute;
  top: 0;
  left: 0;
}

#capacity img {
  height: 40px;
  width: 40px;
  margin: 6px;
  padding: 4px;
}

img.capacity_ok {
  background-color: #003d00bf;
}

/*# sourceMappingURL=index.3aac3d89.css.map */
