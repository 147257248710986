#capacity{
    position: fixed;
    border: solid 1px grey;
    bottom: 0px;
    left: 420px;
    width: 60px;
    height: 60px;
    background-color: black;
}

.progressBar {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(210, 104, 72, 0.5);
  }
  
#capacity img {
    padding: 4px;
    margin: 6px;
    height: 40px;
    width: 40px;
    /* border: 1px solid grey; */
}

img.capacity_ok {
    background-color: rgba(0, 61, 0, 0.749);
}

img.capacity_no {
    /* background-color: rgb(56, 0, 27); */
}
